<template>
  <xzd-list-page
    ref="xzdListPage"
    module-name="user"
    :get-list-func="getList"
    :queryParam="queryParam"
    :show-import-excel-button="false"
    :show-export-excel-button="false"
    can-select>
    <div slot="searchBox">
      <user-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <save-user-button @xzdListPageReload="xzdListPageReload"/>
      <delete-user-button :ids="selectedRows.map(v=>v.id)" @xzdListPageReload="xzdListPageReload"/>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column prop="typeName" :label="$t('user.type')" width="100px"/>
      <el-table-column prop="companyName" :label="$t('user.company')"/>
      <el-table-column prop="account" :label="$t('user.account')"/>
      <el-table-column prop="password" :label="$t('user.password')"/>
      <el-table-column prop="name" :label="$t('user.name')"/>
      <el-table-column prop="realName" :label="$t('user.realName')"/>
      <el-table-column prop="sort" :label="$t('user.sort')" width="80px"/>
      <el-table-column :label="$t('common.operate')" width="200" align="center">
        <template slot-scope="scope">
          <save-user-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
          <delete-user-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload"/>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/user";
import UserListSearchBox from "./UserListSearchBox";
import SaveUserButton from "../buttons/SaveUserButton";
import DeleteUserButton from "../buttons/DeleteUserButton";
import XzdListPage from "@/components/xzd/listPage/XzdListPage";

export default {
  name: "UserList",
  components: {XzdListPage, DeleteUserButton, SaveUserButton, UserListSearchBox},
  data() {
    return {
      queryParam: {},
      getList
    }
  },
  methods: {
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    }
  }
}
</script>